///////////////////////////////////////////////////////////
// File        : BenefitsCard2Row.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import { DataContext, useIsMounted } from "~/Context";
import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import "./BenefitsCard2Row.scss";

// Function Definition
function BenefitsCard2Row(props) {
  // console.info("BenefitsCard2Row.render");
  const dataContext = useContext(DataContext);
  const isMounted = useIsMounted();
  const {id} = props;
  const [number, setNumber] = useState("");
  const [note, setNote] = useState("");
  const [description, setDescription] = useState("");
  const queryEndpoint = dataContext.retrieveEndpoint(id);
  useEffect(() => {
    try {
      dataContext.Get(queryEndpoint)
        .then((response) => {
          const fields = response.data.fields; // jsonpath.value(response, "$.data.fields"); // Get the children for this component
          // console.info(JSON.stringify(fields, null, 2));
          if (fields && isMounted.current) {
            fields.number && setNumber(fields.number);
            fields.note && setNote(fields.note);
            fields.description && setDescription(fields.description);
          }
        })
        .catch((error) => console.error(`[API] ${error} [${id}]`))
        ;
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }, []);
  return (
    <div className="benefitsCard2Row">
      <div className="data-list-item media mb-4">
        <div className="data-overview mr-md-4 text-center">
          <div className="number">{number}</div>
          <div className="note">{note}</div>
        </div>
        <div className="data-desc media-body">
          {description}
        </div>
      </div>
    </div>
  );
}

// Interface
BenefitsCard2Row.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // <value>: PropTypes.string,
});
BenefitsCard2Row.defaultProps = Object.assign({}, defaultComponentProps, {
  // <value>: "",
});

// Exports

export default BenefitsCard2Row;

