///////////////////////////////////////////////////////////
// File        : BenefitsCard2.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import { DataContext, useIsMounted } from "~/Context";
import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import "./BenefitsCard2.scss";
import BenefitsCard2Row from "~/Components/Items/BenefitsCard2Row/BenefitsCard2Row";

// Function Definition
function BenefitsCard2(props) {
  // console.info("BenefitsCard2.render");
  const dataContext = useContext(DataContext);
  const isMounted = useIsMounted();
  const {id} = props;
  const [titleNote, setTitleNote] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [rows, setRows] = useState("");
  const [more, setMore] = useState("");
  const queryEndpoint = dataContext.retrieveEndpoint(id);
  useEffect(() => {
    try {
      dataContext.Get(queryEndpoint)
        .then((response) => {
          const fields = response.data.fields; // jsonpath.value(response, "$.data.fields"); // Get the children for this component
          if (fields && isMounted.current) {
            fields.titleNote && setTitleNote(fields.titleNote);
            fields.title && setTitle(fields.title);
            fields.description && setDescription(fields.description);
            if (fields.rows && Array.isArray(fields.rows)) {
              setRows(fields.rows.map((item, index) => (
                <BenefitsCard2Row key={item.sys.id} id={item.sys.id} />
              )));
            }
            fields.more && setMore(fields.more);
          }
        })
        .catch((error) => console.error(`[API] ${error} [${id}]`))
        ;
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }, []);
  return (
    <div className="item-content col-12 col-lg-7 col-xl-6 order-lg-1 pl-lg-5 benefitsCard2">
      <div className="title-note mb-1">{titleNote}</div>
      <h4 className="title mb-3">{title}</h4>
      <div className="desc mb-3">
        {description}
      </div>
      <div className="data-list">
        {rows}
      </div>
      <div>
        <a className="more-link" href={more}>
          Find out more <span className="link-arrow">&rarr;</span>
        </a>
      </div>
    </div>
  );
}

// Interface
BenefitsCard2.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // <value>: PropTypes.string,
});
BenefitsCard2.defaultProps = Object.assign({}, defaultComponentProps, {
  // <value>: "",
});

// Exports

export default BenefitsCard2;

