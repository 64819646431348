///////////////////////////////////////////////////////////
// File        : Shop.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import { DataContext, useIsMounted } from "~/Context";
import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import "./Shop.scss";
import Template from "~/Components/Pages/_Template/_Template";
import BenefitsSection from "~/Components/Layouts/BenefitsSection/BenefitsSection";

// Function Definition
function Shop(props) {
  // console.info("Shop.render");
  const dataContext = useContext(DataContext);
  const isMounted = useIsMounted();
  const {id} = props;
  const [pageId, setPageId] = useState("");
  const [items, setItems] = useState("");
  const queryEndpoint = dataContext.retrieveEndpoint(id);
  useEffect(() => {
    try {
      dataContext.Get(queryEndpoint)
        .then((response) => {
          const fields = response.data.fields; // jsonpath.value(response, "$.data.fields"); // Get the children for this component
          // console.info(JSON.stringify(fields, null, 2));
          if (fields && isMounted.current) {
            fields.id && setPageId(fields.id);
            // Need queue of component types here, matching order in CMS...FRAGILE!
            if (fields.items && Array.isArray(fields.items)) {
              // Pop component type off queue and create it here
              // <Component key={item.sys.id} id={item.sys.id} />
              setItems(fields.items.map((item, index) => {
                /*
                switch (item.sys.contentType.sys.id) { // We don't have this yet
                  case "layoutsBenefitsSection":
                    return (<BenefitsSection key={item.sys.id} id={item.sys.id} />);
                  default:
                    return null;
                }
                */
                return (<BenefitsSection key={item.sys.id} id={item.sys.id} />);
              }));
            }
          }
        })
        .catch((error) => console.error(`[API] ${error} [${id}]`))
        ;
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }, []);
  return (
    <Template id={id}>
      <section className="shop">
        <div className="container">
          {items}
        </div>
      </section>
    </Template>
  );
}

// Interface
Shop.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // <value>: PropTypes.string,
});
Shop.defaultProps = Object.assign({}, defaultComponentProps, {
  // <value>: "",
});

// Exports

export default Shop;

