///////////////////////////////////////////////////////////
// File        : BenefitsSection.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import { DataContext, useIsMounted } from "~/Context";
import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import "./BenefitsSection.scss";
import BenefitsRow from "~/Components/Layouts/BenefitsRow/BenefitsRow";

// Function Definition
function BenefitsSection(props) {
  // console.info("BenefitsSection.render");
  const dataContext = useContext(DataContext);
  const isMounted = useIsMounted();
  const {id} = props;
  const [title, setTitle] = useState("");
  const [items, setItems] = useState("");
  const queryEndpoint = dataContext.retrieveEndpoint(id);
  useEffect(() => {
    try {
      dataContext.Get(queryEndpoint)
        .then((response) => {
          const fields = response.data.fields; // jsonpath.value(response, "$.data.fields"); // Get the children for this component
          // console.info(JSON.stringify(fields, null, 2));
          if (fields && isMounted.current) {
            fields.title && setTitle(fields.title);
            if (fields.items && Array.isArray(fields.items)) {
              setItems(fields.items.map((item, index) => (
                <BenefitsRow key={item.sys.id} id={item.sys.id} />
              )));
            }
          }
        })
        .catch((error) => console.error(`[API] ${error} [${id}]`))
        ;
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }, []);
  return (
    <section className="benefits-section py-5 benefitsSection">
      <div className="container">
        <h3 className="mb-lg-5 text-center section-title single-col-max mx-auto">{title}</h3>
        {items}
      </div>
    </section>
  );
}

// Interface
BenefitsSection.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // <value>: PropTypes.string,
});
BenefitsSection.defaultProps = Object.assign({}, defaultComponentProps, {
  // <value>: "",
});

// Exports

export default BenefitsSection;

