///////////////////////////////////////////////////////////
// File        : BenefitsRow.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import { DataContext, useIsMounted } from "~/Context";
import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import "./BenefitsRow.scss";
import BenefitsCard1 from "~/Components/Items/BenefitsCard1/BenefitsCard1";
import BenefitsCard2 from "~/Components/Items/BenefitsCard2/BenefitsCard2";
import BenefitsCard3 from "~/Components/Items/BenefitsCard3/BenefitsCard3";
import BenefitsVisualisation from "~/Components/Items/BenefitsVisualisation/BenefitsVisualisation";

// Function Definition
function BenefitsRow(props) {
  // console.info("BenefitsRow.render");
  const dataContext = useContext(DataContext);
  const isMounted = useIsMounted();
  const {id} = props;
  const [card, setCard] = useState("");
  const [visualisation, setVisualisation] = useState("");
  const [orientation, setOrientation] = useState("");
  const queryEndpoint = dataContext.retrieveEndpoint(id);
  useEffect(() => {
    try {
      dataContext.Get(queryEndpoint)
        .then((response) => {
          const fields = response.data.fields; // jsonpath.value(response, "$.data.fields"); // Get the children for this component
          // console.info(JSON.stringify(fields, null, 2));
          if (fields && isMounted.current) {
            if (fields.type && fields.card) {
              switch (fields.type) {
                case "1":
                  setCard(<BenefitsCard1 id={fields.card.sys.id} />);
                  break;
                case "2":
                  setCard(<BenefitsCard2 id={fields.card.sys.id} />);
                  break;
                case "3":
                  setCard(<BenefitsCard3 id={fields.card.sys.id} />);
                  break;
                default:
                  break;
              }
            }
            fields.visualisation && setVisualisation(<BenefitsVisualisation id={fields.visualisation.sys.id} />);
            fields.orientation && setOrientation(fields.orientation);
          }
        })
        .catch((error) => console.error(`[API] ${error} [${id}]`))
        ;
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }, []);
  switch (orientation) {
    case "l":
      return (
        <div className="item py-5 benefitsRow">
          <div className="row">
            {card}
            {visualisation}
          </div>
        </div>
      );
    case "r":
      return (
        <div className="item py-5 benefitsRow">
          <div className="row">
            {visualisation}
            {card}
          </div>
        </div>
      );
    default:
      return null;
  }
}

// Interface
BenefitsRow.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // <value>: PropTypes.string,
});
BenefitsRow.defaultProps = Object.assign({}, defaultComponentProps, {
  // <value>: "",
});

// Exports

export default BenefitsRow;

