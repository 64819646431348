///////////////////////////////////////////////////////////
// File        : BenefitsCard3.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import { DataContext, useIsMounted } from "~/Context";
import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import "./BenefitsCard3.scss";

// Function Definition
function BenefitsCard3(props) {
  // console.info("BenefitsCard3.render");
  const dataContext = useContext(DataContext);
  const isMounted = useIsMounted();
  const {id} = props;
  const [titleNote, setTitleNote] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [check1, setCheck1] = useState("");
  const [check2, setCheck2] = useState("");
  const [check3, setCheck3] = useState("");
  const [more, setMore] = useState("");
  const queryEndpoint = dataContext.retrieveEndpoint(id);
  useEffect(() => {
    try {
      dataContext.Get(queryEndpoint)
        .then((response) => {
          const fields = response.data.fields; // jsonpath.value(response, "$.data.fields"); // Get the children for this component
          if (fields && isMounted.current) {
            fields.titleNote && setTitleNote(fields.titleNote);
            fields.title && setTitle(fields.title);
            fields.description && setDescription(fields.description);
            fields.check1 && setCheck1(fields.check1);
            fields.check2 && setCheck2(fields.check2);
            fields.check3 && setCheck3(fields.check3);
            fields.more && setMore(fields.more);
          }
        })
        .catch((error) => console.error(`[API] ${error} [${id}]`))
        ;
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }, []);
  return (
    <div className="item-content col-12 col-lg-7 col-xl-6 pr-lg-5 benefitsCard3">
      <div className="title-note mb-1">{titleNote}</div>
      <h4 className="title mb-3">{title}</h4>
      <div className="desc">
        {description}
      </div>
      <ul className="list-unstyled pt-4">
        <li className="mb-3"><span className="theme-check-icon icon dripicons-checkmark mr-2"></span>{check1}</li>
        <li className="mb-3"><span className="theme-check-icon icon dripicons-checkmark mr-2"></span>{check2}</li>
        <li className="mb-3"><span className="theme-check-icon icon dripicons-checkmark mr-2"></span>{check3}</li>
      </ul>
      <div>
        <a className="more-link" href={more}>
          Find out more <span className="link-arrow">&rarr;</span>
        </a>
      </div>
    </div>
  );
}

// Interface
BenefitsCard3.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // <value>: PropTypes.string,
});
BenefitsCard3.defaultProps = Object.assign({}, defaultComponentProps, {
  // <value>: "",
});

// Exports

export default BenefitsCard3;

